body {
  background: url(./images/index_bg_01.jpg) no-repeat;
  background-size: 100% auto;
  background-position-x: center;
  background-position-y: 1.6rem;
}

@media (max-width: 1024px) {
  body {
    background-position-y: 2rem;
  }
}

@media (max-width: 991px) {
  body {
    background-position-y: 1.2rem;
  }
}

@media (max-width: 767px) {
  body {
    background-position-y: 1rem;
  }
}

.index-banner .swiper .swiper-slide {
  overflow: hidden;
}

.index-banner .swiper .swiper-slide .img .pc {
  display: block;
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-slide .img .pc {
    display: none;
  }
}

.index-banner .swiper .swiper-slide .img .mob {
  display: none;
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-slide .img .mob {
    display: block;
  }
}

.index-banner .swiper .swiper-slide .img-t {
  position: absolute;
  left: 0;
  z-index: 1;
  width: 100%;
  box-sizing: border-box;
  top: 28%;
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-slide .img-t {
    top: 15%;
  }
}

.index-banner .swiper .swiper-slide .img-t img {
  display: block;
  opacity: 0;
  transform: translateY(1rem);
  transition: all .8s;
  transition-delay: .6s;
  width: auto;
  height: auto;
  max-height: 1.94rem;
  max-width: 90%;
}

@media (max-width: 1024px) {
  .index-banner .swiper .swiper-slide .img-t img {
    max-height: 2rem;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-slide .img-t img {
    max-height: 1rem;
  }
}

.index-banner .swiper .swiper-slide .img-t .link {
  opacity: 0;
  transform: translateY(0.5rem);
  transition: all .8s;
  transition-delay: .9s;
  margin-top: 0.9rem;
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-slide .img-t .link {
    margin-top: 0.5rem;
  }
}

.index-banner .swiper .swiper-slide .img-t .link .common-more {
  padding: 0.1rem 0.6rem;
}

.index-banner .swiper .swiper-slide.swiper-slide-active .img-t img, .index-banner .swiper .swiper-slide.swiper-slide-active .img-t .link {
  opacity: 1;
  transform: translateY(0%);
}

.index-banner .swiper .swiper-pagination {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: auto;
  left: auto;
  border-radius: 5px;
  overflow: hidden;
  top: 28%;
  bottom: auto;
  left: 0.8rem;
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-pagination {
    top: auto;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-pagination {
    bottom: 0.3rem;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper .swiper-pagination {
    left: 0.4rem;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-pagination {
    left: 0.2rem;
  }
}

.index-banner .swiper .swiper-pagination .swiper-pagination-bullet {
  background-color: #fff;
  opacity: .3;
  transition: all .3s;
  border-radius: 0;
  margin: 0;
  height: 0.4rem;
  width: 0.04rem;
}

@media (max-width: 1024px) {
  .index-banner .swiper .swiper-pagination .swiper-pagination-bullet {
    height: 0.5rem;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-pagination .swiper-pagination-bullet {
    height: 0.6rem;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-pagination .swiper-pagination-bullet {
    height: 0.3rem;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper .swiper-pagination .swiper-pagination-bullet {
    width: 0.06rem;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-pagination .swiper-pagination-bullet {
    width: 0.08rem;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-pagination .swiper-pagination-bullet {
    width: 0.06rem;
  }
}

.index-banner .swiper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  opacity: 1;
}

.index-pro-cont {
  position: relative;
}

.index-pro-cont::before, .index-pro-cont::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  background-size: 100% auto;
  background-repeat: no-repeat;
}

.index-pro-cont::before {
  background-image: url(./images/index_bg2.png);
  background-position: top left;
}

.index-pro-cont::after {
  background-image: url(./images/index_bg10.png);
  background-position: bottom right;
}

.index-pro-cont .page-bg1, .index-pro-cont .page-bg2 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
  pointer-events: none;
}

.index-pro-cont .page-bg1 {
  background-image: url(./images/index_bg_02.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position-x: 0;
  background-position-y: 58%;
}

@media (max-width: 767px) {
  .index-pro-cont .page-bg1 {
    background-size: 150% auto;
  }
}

.index-pro-cont .page-bg2 {
  background-image: url(./images/index_bg8.jpg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: bottom center;
}

@media (max-width: 767px) {
  .index-pro-cont .page-bg2 {
    background-size: 150% auto;
  }
}

.index-pro-cont .fixed-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  pointer-events: none;
  z-index: -1;
}

.index-pro-cont .fixed-bg.fixed {
  position: fixed;
  top: auto !important;
  bottom: 0;
}

.index-pro-cont .fixed-bg.abs {
  position: absolute;
  top: auto !important;
  bottom: 0;
}

.index-pro {
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 2;
  display: flex;
  padding-top: 9.375vw;
  padding-bottom: 3.64vw;
}

@media (max-width: 767px) {
  .index-pro {
    display: block;
  }
}

@media (max-width: 767px) {
  .index-pro {
    padding-top: 1rem;
  }
}

@media (max-width: 767px) {
  .index-pro {
    padding-bottom: 0.4rem;
  }
}

.index-pro .bg {
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: 100% auto;
}

.index-pro::before, .index-pro::after {
  pointer-events: none;
}

.index-pro.reverse {
  flex-direction: row-reverse;
}

.index-pro.pro2 .img, .index-pro.pro4 .img {
  position: relative;
}

.index-pro.pro2 .img::after, .index-pro.pro4 .img::after {
  content: '';
  display: block;
  position: absolute;
  left: 50%;
  bottom: 0;
  z-index: -1;
  background: url(./images/index_bg5_1.png) no-repeat center;
  background-size: contain;
  transform: translate3d(-51.5%, 9%, 0);
  width: 60.93vw;
  height: 56.25vw;
}

@media (max-width: 767px) {
  .index-pro.pro2 .img::after, .index-pro.pro4 .img::after {
    width: 115vw;
  }
}

@media (max-width: 767px) {
  .index-pro.pro2 .img::after, .index-pro.pro4 .img::after {
    height: 106vw;
  }
}

.index-pro.pro4 {
  padding-bottom: 4.64vw;
}

@media (max-width: 767px) {
  .index-pro.pro4 {
    padding-bottom: 0.4rem;
  }
}

.index-pro .text {
  flex-shrink: 0;
  width: 35.93vw;
}

@media (max-width: 1024px) {
  .index-pro .text {
    width: 49.93vw;
  }
}

@media (max-width: 767px) {
  .index-pro .text {
    width: 100%;
  }
}

.index-pro .text .title {
  margin-bottom: 0.25rem;
}

.index-pro .text .title img {
  display: block;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 4.89vw;
}

@media (max-width: 1024px) {
  .index-pro .text .title img {
    max-height: 1.1rem;
  }
}

@media (max-width: 991px) {
  .index-pro .text .title img {
    max-height: 1.3rem;
  }
}

@media (max-width: 767px) {
  .index-pro .text .title img {
    max-height: 1.2rem;
  }
}

@media screen and (max-width: 1920px) {
  .index-pro .text .title img {
    max-height: 0.94rem;
  }
}

@media screen and (max-width: 1920px) and (max-width: 1024px) {
  .index-pro .text .title img {
    max-height: 1.1rem;
  }
}

@media screen and (max-width: 1920px) and (max-width: 991px) {
  .index-pro .text .title img {
    max-height: 1.3rem;
  }
}

@media screen and (max-width: 1920px) and (max-width: 767px) {
  .index-pro .text .title img {
    max-height: 1.2rem;
  }
}

.index-pro .text .desc {
  font-size: 0.28rem;
}

@media (max-width: 1024px) {
  .index-pro .text .desc {
    font-size: 0.3rem;
  }
}

@media (max-width: 991px) {
  .index-pro .text .desc {
    font-size: 0.4rem;
  }
}

@media (max-width: 767px) {
  .index-pro .text .desc {
    font-size: 0.3rem;
  }
}

@media screen and (max-width: 1920px) {
  .index-pro .text .desc {
    font-size: 0.24rem;
  }
}

@media screen and (max-width: 1920px) and (max-width: 1024px) {
  .index-pro .text .desc {
    font-size: 0.3rem;
  }
}

@media screen and (max-width: 1920px) and (max-width: 991px) {
  .index-pro .text .desc {
    font-size: 0.4rem;
  }
}

@media screen and (max-width: 1920px) and (max-width: 767px) {
  .index-pro .text .desc {
    font-size: 0.34rem;
  }
}

.index-pro .text .link {
  margin-top: 0.5rem;
  margin-bottom: 1.65rem;
}

@media (max-width: 767px) {
  .index-pro .text .link {
    margin-bottom: 0.65rem;
  }
}

.index-pro .img {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34.375vw;
}

@media (max-width: 767px) {
  .index-pro .img {
    width: 100%;
  }
}

.index-pro .img img {
  display: block;
  width: auto;
  height: auto;
  max-width: 100%;
}

@media (max-width: 767px) {
  .index-pro .img img {
    max-width: 65%;
  }
}

.index-media {
  position: relative;
  overflow: hidden;
  z-index: 1;
  padding-top: 1.8rem;
  padding-bottom: 1.9rem;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
}

@media (max-width: 767px) {
  .index-media {
    padding-top: 0.8rem;
  }
}

@media (max-width: 767px) {
  .index-media {
    padding-bottom: 0.6rem;
  }
}

@media (max-width: 1024px) {
  .index-media {
    padding-left: 1rem;
  }
}

@media (max-width: 767px) {
  .index-media {
    padding-left: 0.5rem;
  }
}

@media (max-width: 1024px) {
  .index-media {
    padding-right: 1rem;
  }
}

@media (max-width: 767px) {
  .index-media {
    padding-right: 0.5rem;
  }
}

.index-media::before, .index-media::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: -1;
}

.index-media::before {
  background: url(./images/index_bg7_1.png) no-repeat top center;
  background-size: 100% auto;
}

.index-media::after {
  background: url(./images/index_bg7_2.png) no-repeat bottom center;
  background-size: 100% auto;
}

.index-media .media-title {
  text-align: center;
  color: #593d94;
  font-family: 'IntegralCF-Bold';
  font-size: 2.9vw;
  margin-bottom: 1.1rem;
}

@media (max-width: 1024px) {
  .index-media .media-title {
    font-size: 0.76rem;
  }
}

@media (max-width: 991px) {
  .index-media .media-title {
    font-size: 0.86rem;
  }
}

@media (max-width: 767px) {
  .index-media .media-title {
    font-size: 0.6rem;
  }
}

@media (max-width: 767px) {
  .index-media .media-title {
    margin-bottom: 0.4rem;
  }
}

.index-media ul {
  justify-content: space-between;
  display: flex;
}

@media (max-width: 991px) {
  .index-media ul {
    display: block;
  }
}

.index-media ul li {
  flex-shrink: 0;
  box-shadow: 0.08rem 0.15rem 0.2rem rgba(0, 0, 0, 0.3);
  width: 47.5%;
  margin-bottom: 0.8rem;
}

@media (max-width: 991px) {
  .index-media ul li {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .index-media ul li {
    margin-bottom: 0.5rem;
  }
}

@media (max-width: 767px) {
  .index-media ul li {
    margin-bottom: 0.4rem;
  }
}

.index-media ul li iframe {
  display: block;
  width: 100%;
}

.index-media ul li a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.index-media ul li a .iconfont {
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.7rem;
}

@media (max-width: 1024px) {
  .index-media ul li a .iconfont {
    font-size: 0.9rem;
  }
}

@media (max-width: 991px) {
  .index-media ul li a .iconfont {
    font-size: 1.2rem;
  }
}

@media (max-width: 767px) {
  .index-media ul li a .iconfont {
    font-size: 0.8rem;
  }
}

.index-form {
  background: url(../../assets/images/subscribe_bg.jpg) no-repeat center;
  background-size: cover;
  padding-top: 1rem;
  padding-bottom: 1.1rem;
}

@media (max-width: 767px) {
  .index-form {
    padding-top: 0.8rem;
  }
}

@media (max-width: 767px) {
  .index-form {
    padding-bottom: 0.8rem;
  }
}

.index-form .title {
  color: #fff;
  text-align: center;
  font-family: 'IntegralCF-Bold';
  font-size: 2.29vw;
}

@media (max-width: 1024px) {
  .index-form .title {
    font-size: 0.54rem;
  }
}

@media (max-width: 991px) {
  .index-form .title {
    font-size: 0.64rem;
  }
}

@media (max-width: 767px) {
  .index-form .title {
    font-size: 0.56rem;
  }
}

.index-form .desc {
  color: #fff;
  text-align: center;
  margin-bottom: 0.45rem;
}

.index-form .form {
  margin: 0 auto;
  background-color: #fff;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 0.15rem;
  max-width: 8.6rem;
}

@media (max-width: 991px) {
  .index-form .form {
    max-width: 10rem;
  }
}

@media (max-width: 767px) {
  .index-form .form {
    max-width: 100%;
  }
}

.index-form .form form {
  display: flex;
}

.index-form .form form input {
  flex-grow: 1;
  border: none;
  padding-left: 0.35rem;
}

@media (max-width: 767px) {
  .index-form .form form input {
    padding-left: 0;
  }
}

.index-form .form form button {
  border-radius: 5px;
  color: #fff;
  background-color: #593d94;
  cursor: pointer;
  border: none;
  transition: background-color .3s;
  font-size: 0.22rem;
  height: 0.68rem;
  padding: 0 0.4rem;
}

@media (max-width: 1024px) {
  .index-form .form form button {
    font-size: 0.32rem;
  }
}

@media (max-width: 991px) {
  .index-form .form form button {
    font-size: 0.42rem;
  }
}

@media (max-width: 767px) {
  .index-form .form form button {
    font-size: 0.3rem;
  }
}

@media (max-width: 1024px) {
  .index-form .form form button {
    height: 0.8rem;
  }
}

@media (max-width: 991px) {
  .index-form .form form button {
    height: 1rem;
  }
}

@media (max-width: 767px) {
  .index-form .form form button {
    height: 0.8rem;
  }
}

@media (max-width: 767px) {
  .index-form .form form button {
    padding: 0 0.2rem;
  }
}

.index-form .form form button:hover {
  background-color: #3B145E;
}
