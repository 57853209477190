@import "../../assets/styles/utils";

body{
  background: url(./images/index_bg_01.jpg) no-repeat;
  background-size: 100% auto;
  background-position-x: center;
  @include res(background-position-y,1.6rem,(md:2rem,sm:1.2rem,xs:1rem))
}
.index-banner{
  .swiper{
    .swiper-slide{
      overflow: hidden;
      .img{
        .pc{
          @include res(display,block,(xs:none));
        }
        .mob{
          @include res(display,none,(xs:block));
        }
      }
      .img-t{
        position: absolute;
        // top: 45%;
        left: 0;
        z-index: 1;
        // transform: translateY(-50%);
        width: 100%;
        box-sizing: border-box;
        @include res(top, 28%,(xs:15%));
        img{
          display: block;
          opacity: 0;
          transform: translateY(1rem);
          transition: all .8s;
          transition-delay: .6s;
          width: auto;
          height: auto;
          @include res(max-height,1.94rem,(md:2rem,xs:1rem));
          @include res(max-width,90%);
        }
        .link{
          opacity: 0;
          transform: translateY(.5rem);
          transition: all .8s;
          transition-delay: .9s;
          @include res(margin-top, .9rem,(xs:.5rem));
          .common-more{
            @include res(padding,.1rem .6rem);
          }
        }
      }
      &.swiper-slide-active{
        .img-t{
          img,.link{
            opacity: 1;
            transform: translateY(0%);
          }
        } 
      }
    }
    .swiper-pagination{
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: auto;
      left: auto;
      border-radius: 5px;
      overflow: hidden;
      @include res(top,28%,(xs:auto));
      @include res(bottom,auto,(xs:.3rem));
      // @include res(transform,translateY(50%),(xs:translateY(0%)));
      @include res(left,.8rem,(md:.4rem,xs:.2rem));
      .swiper-pagination-bullet{
        background-color: #fff;
        opacity: .3;
        transition: all .3s;
        border-radius: 0;
        margin: 0;
        @include res(height,.4rem,(md:.5rem,sm:.6rem,xs:.3rem));
        @include res(width,.04rem,(md:.06rem,sm:.08rem,xs:.06rem));
        &.swiper-pagination-bullet-active{
          opacity: 1;
        }
      }
    }
  }
}

.index-pro-cont{
  position: relative;
  &::before,&::after{
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    background-size: 100% auto;
    background-repeat: no-repeat;
  }
  &::before{
    background-image: url(./images/index_bg2.png);
    background-position: top left;
  }
  &::after{
    background-image: url(./images/index_bg10.png);
    background-position: bottom right;
  }
  .page-bg1,.page-bg2{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
    pointer-events: none;
  }
  .page-bg1{
    background-image: url(./images/index_bg_02.png);
    background-repeat: no-repeat;
    @include res(background-size,100% auto,(xs:150% auto));
    @include res(background-position-x,0);
    @include res(background-position-y,58%);
  }
  .page-bg2{
    background-image: url(./images/index_bg8.jpg);
    background-repeat: no-repeat;
    @include res(background-size,100% auto,(xs:150% auto));
    @include res(background-position,bottom center);
  }
  .fixed-bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    pointer-events: none;
    z-index: -1;
    &.fixed{
      position: fixed;
      top: auto !important;
      bottom: 0;
    }
    &.abs{
      position: absolute;
      top: auto !important;
      bottom: 0;
    }
  }
}
.index-pro{
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 2;
  @include res(display,flex,(xs:block));
  @include res(padding-top,9.375vw,(xs:1rem));
  @include res(padding-bottom,3.64vw,(xs:.4rem));
  .bg{
    pointer-events: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: 100% auto;
  }
  &::before,&::after{
    pointer-events: none;
  }
  &.reverse{
    flex-direction: row-reverse;
  }
  &.pro2,&.pro4{
    .img{
      position: relative;
      &::after{
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        bottom: 0;
        z-index: -1;
        background: url(./images/index_bg5_1.png) no-repeat center;
        background-size: contain;
        @include res(transform,translate3d(-51.5%,9%,0));
        @include res(width,60.93vw,(xs:115vw));
        @include res(height,56.25vw,(xs:106vw));
      }
    }
  }
  &.pro4{
    @include res(padding-bottom,4.64vw,(xs:.4rem));
  }
  .text{
    flex-shrink: 0;
    @include res(width,35.93vw,(md:49.93vw,xs:100%));
    .title{
      @include res(margin-bottom,.25rem);
      img{
        display: block;
        width: auto;
        height: auto;
        @include res(max-width,100%);
        @include res(max-height,4.89vw,(md:1.1rem,sm:1.3rem,xs:1.2rem));
        @media screen and(max-width:1920px) {
          @include res(max-height,.94rem,(md:1.1rem,sm:1.3rem,xs:1.2rem));
        }
      }
    }
    .desc{
      @include res(font-size, .28rem,(md:.3rem,sm:.4rem,xs:.3rem));
      @media screen and(max-width:1920px) {
        @include res(font-size, .24rem,(md:.3rem,sm:.4rem,xs:.34rem));
      }
    }
    .link{
      @include res(margin-top,.5rem);
      @include res(margin-bottom,1.65rem,(xs:.65rem));
    }
  }
  .img{
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    @include res(width,34.375vw,(xs:100%));
    img{
      display: block;
      width: auto;
      height: auto;
      @include res(max-width,100%,(xs:65%));
    }
  }
}

.index-media{
  position: relative;
  overflow: hidden;
  z-index: 1;
  @include res(padding-top,1.8rem,(xs:.8rem));
  @include res(padding-bottom,1.9rem,(xs:.6rem));
  @include res(padding-left,1.2rem,(md:1rem,xs:.5rem));
  @include res(padding-right,1.2rem,(md:1rem,xs:.5rem));
  &::before,&::after{
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: -1;
  }
  &::before{
    background: url(./images/index_bg7_1.png) no-repeat top center;
    background-size: 100% auto;
  }
  &::after{
    background: url(./images/index_bg7_2.png) no-repeat bottom center;
    background-size: 100% auto;
  }
  .media-title{
    text-align: center;
    color: $color-main;
    font-family: 'IntegralCF-Bold';
    @include res(font-size, 2.9vw,(md:.76rem,sm:.86rem,xs:.6rem));
    @include res(margin-bottom, 1.1rem,(xs:.4rem));
  }
  ul{
    justify-content: space-between;
    @include res(display,flex,(sm:block));
    li{
      flex-shrink: 0;
      box-shadow: .08rem .15rem .2rem rgba(0,0,0,.3);
      @include res(width,47.5%,(sm:100%));
      @include res(margin-bottom, .8rem,(sm:.5rem,xs:.4rem));
      iframe{
        display: block;
        width: 100%;
      }
      a{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        .iconfont{
          color: rgba(255,255,255,.8);
          @include res(font-size, .7rem,(md:.9rem,sm:1.2rem,xs:.8rem));
        }
      }
    }
  }
}

.index-form{
  background: url(../../assets/images/subscribe_bg.jpg) no-repeat center;
  background-size: cover;
  // @include res(background-position,center,(xs: left center));
  @include res(padding-top,1rem,(xs:.8rem));
  @include res(padding-bottom,1.1rem,(xs:.8rem));
  .title{
    color: #fff;
    text-align: center;
    font-family: 'IntegralCF-Bold';
    @include res(font-size, 2.29vw,(md:.54rem,sm:.64rem,xs:.56rem));
  }
  .desc{
    color: #fff;
    text-align: center;
    @include res(margin-bottom,.45rem);
  }
  .form{
    margin: 0 auto;
    background-color: #fff;
    border-radius: 5px;
    box-sizing: border-box;
    @include res(padding,.15rem);
    @include res(max-width,8.6rem,(sm:10rem,xs:100%));
    form{
      display: flex;
      input{
        flex-grow: 1;
        border: none;
        @include res(padding-left,.35rem,(xs:0));
      }
      button{
        border-radius: 5px;
        color: #fff;
        background-color: $color-main;
        cursor: pointer;
        border: none;
        transition: background-color .3s;
        @include res(font-size, .22rem,(md:.32rem,sm:.42rem,xs:.3rem));
        @include res(height,.68rem,(md:.8rem,sm:1rem,xs:.8rem));
        @include res(padding, 0 .4rem,(xs:0 .2rem));
        &:hover{
          background-color: $color-main-dark;
        }
      }
    }
  }
}

